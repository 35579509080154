import React, { useState, useEffect } from 'react';
import { BehaviorSubject } from 'rxjs';
import {
  useLocation,
} from "react-router-dom";
import decodeJwt from 'jwt-decode';

// Based on:
// https://jasonwatmore.com/post/2019/04/06/react-jwt-authentication-tutorial-example

interface DecodedJwt {
  user_claims: { name: string };
}
function _decodeJwt(jwt: string): DecodedJwt {
  return decodeJwt(jwt);
}

const currentJwtStringSubject: BehaviorSubject<string | null> = (() => {
  return new BehaviorSubject(localStorage.getItem('currentJwtString'));
})();

export const authenticationService = {
  logout,
  currentJwtString: currentJwtStringSubject.asObservable(),
  get currentJwtStringValue() { return currentJwtStringSubject.value }
};

export function AcceptJWTAndRedirect() {
  const location = useLocation()
  let params = new URLSearchParams(location.search);
  
  const jwtString = params.get('access_token');
  if (!jwtString) {
    return null;
  }

  // Remove access_token from the URL.
  params.delete('access_token');
  window.history.replaceState({}, '', `${location.pathname}?${params}`);

  localStorage.setItem('currentJwtString', jwtString);
  currentJwtStringSubject.next(jwtString);
  return null;
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem('currentJwtString');
  currentJwtStringSubject.next(null);
}

export function LoginButton() {
  const [token, setToken] = useState(authenticationService.currentJwtStringValue);

  useEffect(() => {
    authenticationService.currentJwtString.subscribe(setToken);
  }, []);

  function redirectToLogin() {
    const frontend_url = encodeURIComponent(window.location.href);
    window.location.href = `${process.env.REACT_APP_BACKEND_URL}/api/login?frontend_url=${frontend_url}`;
  };

  if (token) {
    const jwt = _decodeJwt(token);
    return (
      <div>
        <span>Logged in as {jwt.user_claims.name}.</span>
        <button className="uk-button uk-button-default uk-margin-left" onClick={() => { authenticationService.logout() }} >
          <span className="icon" style={{color: '#000'}}>Logout</span>
        </button>
      </div>
    );
  } else {
    return (
      <button className="uk-button uk-button-default" onClick={() => { redirectToLogin() }} >
        <span className="icon" style={{color: '#000'}}>Login</span>
      </button>
    );
  }
}